'use es6';

import currencySymbols from 'i18n-data/data/modern/currency';
import spacename from '../internal/spacename';
import I18n from 'I18n';

// Exported for testing purposes
export const apply = target => {
  spacename(target, {
    currencySymbols
  });
};

// This usage of I18n at eval time is safe because we're adding data, not accessing it
// eslint-disable-next-line hubspot-dev/no-unsafe-i18n-at-module-eval
apply(I18n);